import React, { useEffect, useRef, useState } from 'react';

var useOutsideClick = function useOutsideClick() {
  var initialIsVisible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  var _useState = useState(initialIsVisible),
      visible = _useState[0],
      setVisible = _useState[1];

  var ref = useRef(null);

  var handleClickOutside = function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(function () {
    document.addEventListener('click', handleClickOutside, true);
    return function () {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return {
    ref: ref,
    visible: visible,
    setVisible: setVisible
  };
};

export default useOutsideClick;