var smoothScroll = {
  timer: null,
  stop: function stop() {
    clearTimeout(this.timer);
  },
  scrollTo: function scrollTo(id, callback) {
    var _this = this;

    var distance = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    var cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
    var settings = {
      duration: 400,
      easing: {
        outQuint: function outQuint(x, t, b, c, d) {
          return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
        }
      }
    };
    var percentage;
    var startTime;
    var node = id ? document.querySelector("." + id) : document.body;

    if (!node) {
      return;
    }

    var nodeTop = node.offsetTop;
    var nodeHeight = node.offsetHeight;
    var body = document.body;
    var html = document.documentElement;
    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight); // var _header = document.querySelector(".header")
    // var headerHeight = _header ? _header.offsetHeight : 0

    var windowHeight = window.innerHeight;
    var offset = window.pageYOffset;
    var delta = nodeTop - offset;
    var bottomScrollableY = height - windowHeight;
    var targetY = delta - distance; // (bottomScrollableY < delta) ?
    // bottomScrollableY - (height - nodeTop - nodeHeight + offset) - 50:

    startTime = Date.now();
    percentage = 0;

    if (this.timer) {
      cancelAnimationFrame(this.timer);
    }

    var step = function step() {
      var yScroll;
      var elapsed = Date.now() - startTime;

      if (elapsed > settings.duration) {
        cancelAnimationFrame(_this.timer);
      }

      percentage = elapsed / settings.duration;

      if (percentage > 1) {
        cancelAnimationFrame(_this.timer);

        if (callback) {
          callback();
        }
      } else {
        yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
        window.scrollTo(0, yScroll);
        _this.timer = requestAnimationFrame(step);
      }
    };

    this.timer = requestAnimationFrame(step);
  }
};
export default smoothScroll;