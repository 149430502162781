import { useEffect, useRef } from "react"; // Naive implementation - in reality would want to attach
// a window or resize listener. Also use state/layoutEffect instead of ref/effect
// if this is important to know on initial client render.
// It would be safer to  return null for unmeasured states.

export var useDimensions = function useDimensions(ref) {
  var dimensions = useRef({
    width: 0,
    height: 0
  });
  useEffect(function () {
    dimensions.current.width = ref.current.offsetWidth;
    dimensions.current.height = ref.current.offsetHeight;
  }, []);
  return dimensions.current;
};