import React, { useContext, useRef, useState, useEffect } from "react";
import ContainerFluid from "../ContainerFluid";
import Link from "next/link";
import styles from "./Header.module.scss";
import classNames from "classnames";
import useScrollPosition from "hooks/useScroll";
import Image from "../ImgComponent";
import smoothScroll from "common/smoothscroll";
import {
  AnimatePresence,
  AnimateSharedLayout,
  motion,
  useCycle,
} from "framer-motion";
import DropDownList from "../DropDownComponent";
import MenuItem from "./MenuItem";
import MenuToggle from "./MenuToggle";
import { useDimensions } from "../../hooks/useDimensions";
import { useRouter } from "next/router";
import LangContext from "../../context/LangContext";
import { langs } from "../../common/lang";
import headerContent from "../../i18n/header";
import SearchForm from "./SearchForm";
import { ScrollContext } from "./../../context/ScrollContext";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 110% 25px)`,
    background: "#29abe2",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(20px at 110% 25px)",
    background: "#fff",
    transition: {
      delay: 0,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const Header = ({ inViewList = "" }) => {
  const [isScroll, setScroll] = useState(false);
  const [isAnimate, setAnimate] = useState(false);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const { scrollPosition, setScrollPosition } = useContext(ScrollContext);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const router = useRouter();
  const _lang = router.locale;
  const _context = useContext(LangContext);
  const menuContent = headerContent[router.locale];
  const isHomePage = router.pathname === "/";

  const variants = {
    open: {
      // opacity: 1,
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      // opacity: 0,
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  useScrollPosition({
    effect: ({ currPos }) => {
      if (currPos.y < -80) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    },
    wait: 100,
  });

  useEffect(() => {
    if (scrollPosition && isHomePage) {
      smoothScroll.scrollTo(scrollPosition, undefined, 66);
    }
  }, [scrollPosition]);

  const onScroll = (className) => {
    smoothScroll.scrollTo(className, undefined, 66);
  };

  const onLangChange = (lang) => {
    if (router.pathname === "/news/[slug]") {
      router.replace(`/`, `/`, { locale: lang.title });
    } else {
      router.replace(
        `/${lang.title}${router.asPath}`,
        `/${lang.title}${router.asPath}`,
        { locale: lang.title }
      );
    }
  };

  return (
    <ContainerFluid
      className={classNames(
        "header",
        styles.header,
        isScroll && styles.isScroll
      )}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <Link href="/" passHref>
            <a className="col-8 col-md-4 col-lg-2">
              <div
                className={classNames(
                  "cursor-pointer",
                  styles.logo,
                  styles.items
                )}
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <img src="/static/logo.png" alt="Estuary Solutions logo" />
              </div>
            </a>
          </Link>
          <AnimatePresence>
            <AnimateSharedLayout>
              <motion.div
                initial={false}
                animate={isOpen ? "open" : "closed"}
                custom={height}
                ref={containerRef}
                className={classNames("col-4 col-md-8 col-lg-10", styles.menu)}
              >
                <div className="d-flex d-lg-none align-items-center justify-content-end">
                  <MenuToggle
                    className={styles.items}
                    toggle={() => toggleOpen()}
                  />
                  <motion.div
                    className={styles.mb_menu_background}
                    variants={sidebar}
                  />
                  <div
                    className={styles.items}
                    style={{
                      display: "inline-flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: "10px",
                    }}
                  >
                    <DropDownList
                      wrapperClass="d-block d-lg-none"
                      position="right"
                      data={langs}
                      defaultSelected={
                        langs.find((langItem) => langItem.title === _lang) ||
                        data[1]
                      }
                      onChange={(lang) => onLangChange(lang)}
                    />
                  </div>
                </div>
                {isOpen && (
                  <motion.div
                    variants={variants}
                    className={classNames(
                      styles.mb_menu,
                      "d-flex d-lg-none flex-column justify-content-center text-center"
                    )}
                  >
                    <>
                      <SearchForm locale={_lang} />
                      <MenuItem
                        isMobile={true}
                        onClick={() => {
                          isHomePage
                            ? onScroll("home-banner")
                            : setScrollPosition("home-banner");
                        }}
                        isAnimate={isAnimate}
                        setAnimate={setAnimate}
                        title={menuContent.home}
                        isInView={inViewList === "home-banner"}
                        link={!isHomePage && "/"}
                      />
                      {/* <MenuItem
                          isMobile={true}
                          onClick={() => {
                            onScroll("home-work")
                            toggleOpen()
                          }}
                          isAnimate={isAnimate}
                          setAnimate={setAnimate}
                          title={menuContent.work}
                          isInView={inViewList === "home-work"}
                        /> */}
                      <MenuItem
                        isMobile={true}
                        onClick={() => {
                          isHomePage
                            ? onScroll("home-solution")
                            : setScrollPosition("home-solution");
                        }}
                        isAnimate={isAnimate}
                        setAnimate={setAnimate}
                        title={menuContent.solution}
                        isInView={inViewList === "home-solution"}
                        link={!isHomePage && "/"}
                      />
                      <MenuItem
                        isMobile={true}
                        onClick={() => setScrollPosition("")}
                        isAnimate={isAnimate}
                        setAnimate={setAnimate}
                        title={menuContent.news}
                        isInView={
                          isHomePage
                            ? inViewList === "home-news"
                            : typeof window !== "undefined" &&
                              router.pathname === "/news"
                        }
                        link="/news"
                      />
                      {/* <MenuItem
                          isMobile={true}
                          isAnimate={isAnimate}
                          setAnimate={setAnimate}
                          title="Contact Us"
                          link="/contact"
                        /> */}
                    </>
                    <>
                      {/* <MenuItem
                          isMobile={true}
                          isAnimate={isAnimate}
                          setAnimate={setAnimate}
                          title="Home"
                          link="/"
                        /> */}
                      {/* <MenuItem
                          isMobile={true}
                          isAnimate={isAnimate}
                          setAnimate={setAnimate}
                          title="Contact Us"
                          link="/contact"
                        /> */}
                    </>
                  </motion.div>
                )}
                <div className="d-none d-lg-flex align-items-center justify-content-end">
                  <MenuItem
                    onClick={() => {
                      isHomePage
                        ? onScroll("home-banner")
                        : setScrollPosition("home-banner");
                    }}
                    isAnimate={isAnimate}
                    setAnimate={setAnimate}
                    title={menuContent.home}
                    isInView={inViewList === "home-banner"}
                    link={!isHomePage && "/"}
                  />
                  {/* <MenuItem 
                    onClick={() => onScroll("home-work")}
                    isAnimate={isAnimate}
                    setAnimate={setAnimate}
                    title={menuContent.work}
                    isInView={inViewList === "home-work"}
                  /> */}
                  <MenuItem
                    onClick={() => {
                      isHomePage
                        ? onScroll("home-solution")
                        : setScrollPosition("home-solution");
                    }}
                    isAnimate={isAnimate}
                    setAnimate={setAnimate}
                    title={menuContent.solution}
                    isInView={inViewList === "home-solution"}
                    link={!isHomePage && "/"}
                  />
                  <MenuItem
                    onClick={() => setScrollPosition("")}
                    isAnimate={isAnimate}
                    setAnimate={setAnimate}
                    title={menuContent.news}
                    isInView={
                      isHomePage
                        ? inViewList === "home-news"
                        : typeof window !== "undefined" &&
                          router.pathname === "/news"
                    }
                    link="/news"
                  />
                  {/* <MenuItem 
                    isAnimate={isAnimate}
                    setAnimate={setAnimate}
                    title="Contact Us"
                    link="/contact"
                  /> */}
                  <div className="ml-3">
                    <SearchForm locale={_lang} />
                  </div>
                  <div
                    className={styles.items}
                    style={{
                      display: "inline-flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: "15px",
                    }}
                  >
                    <DropDownList
                      position="right"
                      data={langs}
                      defaultSelected={
                        langs.find((langItem) => langItem.title === _lang) ||
                        data[1]
                      }
                      onChange={(lang) => onLangChange(lang)}
                    />
                  </div>
                </div>
              </motion.div>
            </AnimateSharedLayout>
          </AnimatePresence>
        </div>
      </div>
    </ContainerFluid>
  );
};

export default Header;
